var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "LAW_ID",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "LBL0010071",
                    name: "lawId",
                  },
                  model: {
                    value: _vm.searchParam.lawId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "lawId", $$v)
                    },
                    expression: "searchParam.lawId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "LBLUSEFLAG",
                    name: "useFlag",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "gubunTable",
          attrs: {
            title: "",
            tableId: "gubunTable",
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            isExcelDown: false,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
          on: {
            innerBtnClicked: _vm.innerBtnClicked,
            "table-data-change": _vm.tableDataChange,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  [
                    _c("c-text-column", {
                      attrs: { editable: _vm.editable, col: col, props: props },
                      on: {
                        datachange: function ($event) {
                          return _vm.datachange(props, col)
                        },
                      },
                    }),
                    _vm.editable
                      ? _c(
                          "div",
                          [
                            _c(
                              "q-btn-group",
                              {
                                staticClass: "ColumInnerBtnGroup",
                                attrs: { outline: "" },
                              },
                              [
                                _c(
                                  "q-btn",
                                  {
                                    staticClass: "ColumInnerBtn",
                                    attrs: {
                                      icon: "add",
                                      color: "red-6",
                                      "text-color": "white",
                                      align: "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.innerBtnClicked(col, props)
                                      },
                                    },
                                  },
                                  [
                                    _c("q-tooltip", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$label("LBL0010074")) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBL0010073", icon: "add" },
                        on: { btnClicked: _vm.addRowRaw },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.saveData,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveInfoCallback,
                        },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }